'use client';

import { Button, Navbar } from 'flowbite-react';
import { Container } from 'postcss';

export default function ApplicationNavbar() {
  return (
    <div className='app'>
       <Navbar
          fluid
          rounded
          className='bg-transparent'
        >
          <Navbar.Brand href="http://elysium.mp/">
            <span className="self-center whitespace-nowrap text-xl font-semibold text-white">
              elysium.mp
            </span>
          </Navbar.Brand>
          <div className="flex md:order-2">
            <Button className="bg-[#4678f8] hover:bg-[#528bff]" href='https://cdn.rgsvc.io/public/files/RAGEMultiplayer_Setup.exe'>
              Download RAGE:MP
            </Button>
            <Navbar.Toggle />
          </div>
          <Navbar.Collapse>
            <Navbar.Link
              active
              href="#"
            >
              <p>
                Home
              </p>
            </Navbar.Link>  
            <Navbar.Link href="https://elysium.mp/discord" className='text-gray-300'>
              Discord
            </Navbar.Link>
            <div className='flex'>
              <Navbar.Link href="#" className='text-gray-300'>
                UCP
              </Navbar.Link>
              <div className='w-auto h-auto md:flex md:items-center md:justify-center pr-2 pl-2 rounded-md bg-red-400 font-bold text-white ml-2'>SOON</div>
            </div>
          </Navbar.Collapse>
        </Navbar>
    </div>
  )
}


