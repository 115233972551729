import { Button } from "flowbite-react";
import ReportIcon from '@mui/icons-material/Report';
import discord from './discord-mark-white.png'
import { useState } from "react";

function HeroSection({ isMuted, toggleMute }) {
  return (
    <div className="hero-section">
        <p className="text-3xl font-bold mt-40 text-white">Welcome to Elysium Multiplayer.</p>
        <p className="text-lg font-regular text-gray-400">Join our server and dive into the timeless magic of Los Santos.</p>
        <div className='flex'>
          <ReportIcon className="text-[#dd4141]" />
          <p className="text-[#dd4141] font-semibold ml-2" >Server is temporarily in Whitelist.</p>
        </div>
        <div className='flex'>
          <Button className="bg-[#4678f8] hover:bg-[#528bff] mt-3" href='rage://v/connect?ip=elysium.mp:22010'>JOIN SERVER</Button>
          <a
            href='http://elysium.mp/discord'
          >
            <Button className="bg-[#5865F2] hover:bg-[#5865F2] ml-3 mt-3"><img src="https://i.imgur.com/naFPgw5.png" className="w-5 mt-[2px] h-4 mr-2" ></img>JOIN DISCORD SERVER</Button>
          </a>
        </div>
    </div>
  );
}

export default HeroSection;
//<Button className="bg-[#4678f8] hover:bg-[#528bff] mt-3" onClick={toggleMute}>{isMuted ? 'Unmute' : 'Mute'} audio</Button>