import Container from '@mui/material/Container';
import { Card, Button } from 'flowbite-react';
import rockstar from './rgl.png';
import ragemp from './rage-icon.webp';
import elysium from './logo.png';

function StepsToBuy() {
  return (
    <div className="steps relative w-full h-auto top-[140px] md:top-[100px] bg-black flex-col justify-center">
        <Container maxWidth="xl" className="container">
        <p className="text-white font-black text-5xl text-center mt-10 pt-10">STEPS TO PLAY</p>
        <p className="text-white font-regular text-lg text-center">Follow this easy steps to start playing on Elysium Multiplayer.</p>
        <div className='flex mt-10 items-center justify-center row gy-5 g-xl-8 overflow-x-auto'>
            <div className='col-xl-4'>
                <Card
                    className="max-w-sm bg-[#26292B] hover:bg-[#2E3239] mr-4 transition  cursor-default border-none"
                    href="#"
                >
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                        <p>
                        STEP 1
                        </p>
                    </h5>
                    <p className="font-normal text-gray-400">
                        <p>
                        BUY GRAND THEFT AUTO V
                        </p>
                    </p>
                    <Button href='https://store.steampowered.com/app/271590/Grand_Theft_Auto_V/' className='bg-[#1b2838]'>
                        <p>STEAM</p>
                    </Button>
                    <Button href='https://store.epicgames.com/en-US/p/grand-theft-auto-v' className='bg-[#121212]'>
                        <p>EPIC GAMES STORE</p>
                    </Button>
                    <Button href='https://www.rockstargames.com/gta-v' className='bg-[#ffb100] text-black'>
                        <p>ROCKSTAR GAMES LAUNCHER</p>
                    </Button>
                </Card>
            </div>
            <div className='col-xl-4'>
                <Card
                    className="max-w-sm bg-[#26292B] hover:bg-[#2E3239] transition mr-4  cursor-default border-none"
                    href="#"
                >
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                        <p>
                        STEP 2
                        </p>
                    </h5>
                    <p className="font-normal text-gray-400">
                        <p>
                        INSTALL RAGE LAUNCHER
                        </p>
                    </p>
                    <Button href='https://cdn.rgsvc.io/public/files/RAGEMultiplayer_Setup.exe' className="bg-[#4678f8] hover:bg-[#528bff]">
                        <p>DOWNLOAD RAGE</p>
                    </Button>
                </Card>
            </div>

            <div className='col-xl-4'>
                <Card
                    className="max-w-sm bg-[#26292B] hover:bg-[#2E3239] transition  cursor-default border-none"
                    href="#"
                >
                    <h5 className="text-2xl font-bold tracking-tight text-white">
                        <p>
                        STEP 3
                        </p>
                    </h5>
                    <p className="font-normal text-gray-400">
                        <p>
                        JOIN ELYSIUM
                        </p>
                    </p>
                    <Button href='rage://v/connect?ip=elysium.mp:22005' className="bg-[#4678f8] hover:bg-[#528bff]">
                        <p>JOIN</p>
                    </Button>
                </Card>
            </div>
        </div>

        <p className="text-gray-600 font-bold text-xs text-center mt-14">GRAND THEFT AUTO AND GRAND THEFT AUTO: V ARE REGISTERED TRADEMARKS OF TAKE-TWO INTERACTIVE SOFTWARE</p>
        <p className="text-gray-600 font-bold text-xs text-center pb-2">WE ARE NOT AFFILIATED WITH OR ENDORSED BY TAKE-TWO INTERACTIVE SOFTWARE</p>
        <div className='flex items-center justify-center pb-10'>
            <img src="https://i.imgur.com/BIAQ0Kr.png" className='w-10 h-10'></img>
            <img src="https://i.imgur.com/IALx0So.png" className='w-10 h-10 ml-2'></img>
            <img src="https://i.imgur.com/DFJPRad.png" className='w-14 h-14'></img>
        </div>

        </Container>
    </div>
  );
}

export default StepsToBuy;
