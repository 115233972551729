import { Button } from "flowbite-react";
import video from './gta5.mp4';
import { useState } from "react";

function ApplicationVideo({ isMuted }) {

  return (
    <div className="video-section absolute w-full h-[600px] bg-black">
       <video src="https://elysium.mp/static/media/gta5.mp4" autoPlay loop className="w-full h-full" style={{ objectFit: 'fill' }} muted={isMuted}>
          Your browser does not support the video tag.
        </video>
    </div>
  );
}

export default ApplicationVideo;
