import logo from './logo.svg';
import './App.css';
import ApplicationNavbar from './Navbar';
import Container from '@mui/material/Container';
import HeroSection from './HeroSection';
import video from './gta5.mp4';
import ApplicationVideo from './Video';
import StepsToBuy from './StepsToBuy';
import { useState } from 'react';

function App() {

  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  return (
    <div className="app">
      <ApplicationVideo isMuted={isMuted} />
      <Container maxWidth="xl" className="container">
        <ApplicationNavbar />
        <HeroSection isMuted={isMuted} />
      </Container>
      <StepsToBuy />
    </div>
  );
}

export default App;
